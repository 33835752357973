import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../lib/translate';
import { useAccountId } from '../lib/service/account/useAccountId';
import { useCartId } from '../lib/service/cart/useCartId';

import Layout from '../layouts/default';

import Helmet from '../components/helmet';
import MagentoFrame from '../components/magento-frame';
import Section from '../component-elements/content-section';

const MagentoIframePageTemplate = ({
  data: {
    datoCmsMagentoIframePage: {
      metaRobots,
      metaCanonical,
      url: urlPath,
      wantsAccount = false,
      wantsCart = false,
      seoMetaTags: helmetProps,
      ...page
    },
  },
}) => {
  const accountId = useAccountId();

  // will create a fresh cart if there is none
  const cartId = useCartId();

  const isAuthorizedAccess = !wantsAccount
    || (wantsAccount && !accountId.isLoading && accountId.data);

  return (
    <Layout textcolorHeader="black" textcolorHeaderMobile="black">
      {!accountId.isLoading && !cartId.isLoading && (
        <>
          {isAuthorizedAccess && (
            <Section fullWidth>
              <MagentoFrame urlPath={urlPath} accountId={accountId.data} cartId={wantsCart ? cartId.data : undefined} />
            </Section>
          )}

          {!isAuthorizedAccess && (
            <Section noGrid background="Weiß">
              <h2>{t`magento_frame_unauthorized`}</h2>
            </Section>
          )}
        </>
      )}

      <Helmet
        tags={helmetProps}
        metaRobots={metaRobots}
        metaCanonical={metaCanonical}
        title={page.seo?.title}
        titleFallback={t('cms_page_title_fallback')}
      />
    </Layout>
  );
};

export default MagentoIframePageTemplate;

MagentoIframePageTemplate.propTypes = {
  data: PropTypes.shape({
    datoCmsMagentoIframePage: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export const query = graphql`
    query IframeQuery($url: String, $lang: String) {
        datoCmsMagentoIframePage(url: {eq: $url}, locale: {eq: $lang}) {
            seoMetaTags {
              __typename
              tags
            }
            seo {
              title
            }
            metaCanonical
            metaRobots
            
            url
            
            wantsAccount
            wantsCart
        }
    }
`;
