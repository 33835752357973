import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as styles from './component.module.scss';

const MagentoFrame = ({
  accountId,
  cartId,
  urlPath,
}) => {
  const [frameHeight, setFrameHeight] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const onMessage = (event) => {
      if (
        event.origin.indexOf('juwelier-beratung.de') === -1
        && event.origin.indexOf('juwelier-roller.de') === -1
        && event.origin.indexOf('http://localhost') === -1
      ) {
        return;
      }

      if (!event.data.height) {
        return;
      }

      setFrameHeight(event.data.height);
    };

    window.addEventListener('message', onMessage);
    // return a function that cleans up the event listener
    return () => window.removeEventListener('message', onMessage);
  }, []);

  return (
    <div className={styles.mgFrame}>
      {(isLoading) && (
        <div className="loading-spinner"><span className="sr-only">loading</span></div>
      )}

      <iframe
        title="frame"
        src={`${process.env.GATSBY_SHOP_BASEURL}/${urlPath}?${accountId ? `t=${accountId}&` : ''}${cartId ? `c=${cartId}&` : ''}`}
        height={frameHeight}
        width="100%"
        className="responsive-iframe"
        referrerPolicy="no-referrer"
        onLoad={() => setIsLoading(false)}
      />
    </div>
  );
};

MagentoFrame.propTypes = {
  urlPath: PropTypes.string.isRequired,
  accountId: PropTypes.string,
  cartId: PropTypes.string,
};

MagentoFrame.defaultProps = {
  accountId: undefined,
  cartId: undefined,
};

export default MagentoFrame;
